<template>
  <div>
    <vx-card :is-refresh="isLoading">
      <vs-tabs alignment="fixed" v-model="activeTab">
        <vs-tab label="PR Header"
                icon-pack="feather"
                :icon="errors.tabHeader.isError ? 'icon-alert-triangle' : null"
                :tab-color="errors.tabHeader.isError ? 'danger' : null">
          <TabHeader :initData="initDataTabHeader"/>
        </vs-tab>
        <vs-tab label="Detail Item PR"
                icon-pack="feather"
                :icon="errors.tabItem.isError ? 'icon-alert-triangle' : null"
                :tab-color="errors.tabItem.isError ? 'danger' : null">
          <TabItem :initData="initDataTabItem"/>
        </vs-tab>
      </vs-tabs>
    </vx-card>

    <div class="vx-card mt-5" v-if="activeTab === 1">
      <div class="w-full flex justify-end">
        <vs-button :disabled="isLoading || !isAnyUnsavedChanges" icon-pack="feather" icon="icon-save" class="w-full" color="primary" @click="save">
          <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
          <span v-if="!isLoading">Simpan</span>
        </vs-button>
      </div>
    </div>

  </div>
</template>

<script>
import PengadaanRepository from '@/repositories/procurement/pengadaan-repository'
import modulePengadaanAdd from '@/store/modules/procurement/pengadaan/pengadaan-add.store'
import { mapState } from 'vuex'
import _ from 'lodash'
import { convertToFormData } from '@/utilities/common/global-methods'
import { v4 as uuid } from 'uuid'

export default {
  name: 'PengadaanEdit',
  components: {
    TabHeader: () => import('@/views/pages/procurement/pengadaan/parts/TabHeader'),
    TabItem: () => import('@/views/pages/procurement/pengadaan/parts/TabItem')
  },
  mounted () {
    this.$store.commit('procurement/pengadaanAdd/SET_ANY_UNSAVED_CHANGES', true) // default fix bug
    this.getInitData()
    this.listenKeyboardPressed()
  },
  data () {
    return {
      isLoading: false,
      initDataTabHeader: null,
      initDataTabItem: null
    }
  },
  computed: {
    activeTab: {
      get () {
        return this.$store.state.procurement.pengadaanAdd.activeTab
      },
      set (value) {
        this.$store.commit('procurement/pengadaanAdd/SET_ACTIVE_TAB', value)
      }
    },
    ...mapState({
      errors: state => state.procurement.pengadaanAdd.errors,
      isAnyUnsavedChanges: state => state.procurement.pengadaanAdd.isAnyUnsavedChanges,
      dataTabHeader: state => state.procurement.pengadaanAdd.tabHeader,
      dataTabItem: state => state.procurement.pengadaanAdd.tabItem
    })
  },
  methods: {
    getInitData () {
      this.isLoading = true

      const idPengadaan = this.$route.params.idPengadaan
      PengadaanRepository.show(idPengadaan)
        .then(response => {
          this.buildInitData(response.data.data)
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildInitData (initData) {
      // tab header
      const initDataTabHeader = _.pick(initData.pengadaan, ['id', 'id_proyek', 'no_pengadaan', 'tgl_pengajuan', 'judul_pengadaan', 'keterangan'])

      // tab item
      const pengadaanDItems = _.map(initData.pengadaanD, item => _.pick(item, ['id', 'id_item_pengadaan', 'nama_item_pengadaan', 'satuan_item_pengadaan', 'harga', 'qty', 'nama_rekanan', 'keterangan', 'file_penawaran_url', 'is_has_vendors']))
      let initDataTabItem = _.uniqBy(pengadaanDItems, item => item.id_item_pengadaan)
      initDataTabItem = _.map(initDataTabItem, item => {
        let vendors = _.cloneDeep(_.filter(pengadaanDItems, d => (d.is_has_vendors && d.id_item_pengadaan === item.id_item_pengadaan)))
        vendors = _.map(vendors, vendor => {
          return {
            ..._.pick(vendor, ['id', 'nama_rekanan', 'keterangan', 'file_penawaran_url']),
            uuid: uuid()
          }
        })
        return {
          ..._.pick(item, ['id', 'id_item_pengadaan', 'nama_item_pengadaan', 'satuan_item_pengadaan', 'harga', 'qty']),
          uuid: uuid(),
          vendors: vendors
        }
      })

      // with delay to fix bug
      this.initDataTabHeader = initDataTabHeader
      setTimeout(() => {
        this.initDataTabItem = initDataTabItem
      }, 600)
    },

    save () {
      this.$store.commit('procurement/pengadaanAdd/CLEAR_ERRORS')
      this.isLoading = true

      const idPengadaan = this.$route.params.idPengadaan
      const params = convertToFormData({ tabHeader: this.dataTabHeader, tabItem: this.dataTabItem })
      PengadaanRepository.update(idPengadaan, params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            const errors = error.response.data.errors
            if (errors.approver) {
              this.notifyErrorUsingDialog(errors)
            } else {
              this.$store.commit('procurement/pengadaanAdd/SET_ERRORS', errors)
              this.notifyError('Data tidak valid, mohon periksa kembali.')
            }
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.$store.commit('procurement/pengadaanAdd/SET_ANY_UNSAVED_CHANGES', false)
      this.notifySuccess('Data berhasil disimpan.')
      this.$router.push({ name: 'procurement.pengadaan' })
    },

    confirmLeaveOptions () {
      return {
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Ada perubahan yang belum disimpan, yakin ingin berpindah halaman?',
        acceptText: 'Yakin',
        cancelText: 'Batal'
      }
    },

    listenKeyboardPressed () {
      window.addEventListener('keypress', e => this.onKeyboardPressed())
    },

    onKeyboardPressed: _.debounce(function () {
      this.$store.commit('procurement/pengadaanAdd/SET_ANY_UNSAVED_CHANGES', true)
    }, 500)

  },
  beforeCreate () {
    this.$store.registerModule(['procurement', 'pengadaanAdd'], modulePengadaanAdd)
  },
  beforeDestroy () {
    this.$store.commit('procurement/pengadaanAdd/RESET_STATE')
    this.$store.unregisterModule(['procurement', 'pengadaanAdd'])
  },
  beforeRouteLeave (to, from, next) {
    if (this.isAnyUnsavedChanges) {
      this.$vs.dialog({
        ...this.confirmLeaveOptions(),
        accept: () => {
          next()
        }
      })
      return
    }
    next()
  }
}
</script>
